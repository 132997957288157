import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'

import styles from './index.module.scss'


const PrivacyPage = () => {

    return (
        <div>
            <SEO title="プライバシーポリシー" />
            <Header hasBack={true} />

            <div className={styles.box}>
                <h1 className={styles.title_en}>PRIVACY POLICY</h1>
                <div className={styles.title_ja}>プライバシーポリシー</div>
                <div className={styles.date}>Effective Date: December 1, 2020 </div>
                <div className={styles.lead}>
                    <p>快適ライフサービス株式会社（以下、「当社」という）ではフィットネスジムをはじめとする各種サービスのご提供にあたり、より良いサービスの開発およびこれらの販売促進や採用など様々な目的に、お客様からご提供頂いた個人情報を活用致しております。当社は、個人情報の保護に関する法律（以下「個人情報保護法」という）等を遵守するとともに、本指針に従い、個人情報の適切な管理・保護に努めて参ります。</p>
                </div>

                <section>
                    <h2>1. 個人情報</h2>
                    <div>
                        <p>個人情報とは、お客様個人に関する情報であり、当該情報を構成する氏名、生年月日、住所、電話番号、メールアドレス、サービスのご利用に関する情報、当社ウェブサイトの閲覧履歴、その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものも含みます）を指します。</p>
                    </div>
                </section>

                <section>
                    <h2>2. 個人情報の取得方法</h2>
                    <div>
                        <p>当社は、以下の方法により個人情報を取得いたします。</p>
                        <ul>
                            <li>書面による直接的な取得</li>
                            <li>ウェブサイトを通じての取得</li>
                            <li>電子メール、郵便、電話または口頭等の手段による取得</li>
                            <li>上記以外の方法により、個人情報を取得することが想定される方法による取得</li>
                        </ul>
                    </div>
                </section>

                <section>
                    <h2>3. 個人情報の利用目的</h2>
                    <div>
                        <p>個人情報の利用目的は以下の通りです。利用目的を超えて利用することはありません。</p>
                        <ol className={styles.alpha}>
                            <li>
                                <p>お客様に関する個人情報</p>
                                <ul>
                                    <li>お客様によるご要望への対応及びサービスの提供</li>
                                    <li>品質、サービスの向上及び商品、サービスの開発</li>
                                    <li>お客様がご利用されたサービス及び購入された商品等に関するお問合せ、ご依頼等への対応<sup>※1</sup></li>
                                    <li>当社からの各種カタログ・ダイレクトメール・電子メールの送付、アンケートの実施、電話による商品・サービスの紹介及び催事・イベントや店舗の案内等</li>
                                    <li>契約内容の履行及び履行の請求等</li>
                                    <li>法令等に基づく利用<sup>※2</sup></li>
                                </ul>
                                <ul className={styles.attentions}>
                                    <li><span>※1</span>尚、商品及びサービス等に関して生じた問題・不具合等についてお客様へご連絡をする等、お客様より取得した情報を利用して解決を図る場合があります。</li>
                                    <li><span>※2</span>尚、店舗等、当社の施設において、防犯のために録画（録音を含む）をさせて頂いております。</li>
                                </ul>
                            </li>
                            <li>
                                <p>当社お取引先の皆様に関する個人情報</p>
                                <ul>
                                    <li>お取引先へのご連絡、商談、契約の履行、履行請求等</li>
                                    <li>広告媒体、販促物等への掲載</li>
                                    <li>業務管理</li>
                                </ul>
                            </li>
                            <li>
                                <p>当該従業員に関する個人情報</p>
                                <ul>
                                    <li>労務管理等、事業を遂行するにあたり必要な場合</li>
                                    <li>広報への使用</li>
                                    <li>緊急時における対応</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </section>

                <section>
                    <h2>4. 個人情報の第三者への提供</h2>
                    <div>
                        <p>当社は、原則として、お客様本人の同意を得ずに個人情報を第三者へ提供することはありません。提供先、提供情報内容を特定したうえで、お客様本人の同意を得た場合に限り提供します。<br />但し、以下の場合、関係法令に反しない範囲で、お客様ご本人の同意なく個人情報を提供することがあります。</p>
                        <ul>
                            <li>法令に基づく場合</li>
                            <li>人の生命、身体又は財産の保護のために必要がある場合</li>
                            <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要な場合</li>
                            <li>国の機関若しくは地方公共団体等が法令の定める事務を遂行することに対して協力する必要がある場合</li>
                            <li>統計的なデータ等お客様本人を識別できない状態で提供する場合</li>
                            <li>当社との間で、機密保持契約を締結している協力会社、提携会社及び業務委託先会社に対して、利用目的を達成するために必要な範囲内において個人情報の取扱いを委託する場合</li>
                        </ul>
                    </div>
                </section>

                <section>
                    <h2>5. 委託先の管理</h2>
                    <div>
                        <p>弊社は、利用目的の実施に必要な範囲で、個人情報の取扱いを外部に委託することがありますが、委託する場合には弊社が個人情報を適切に取り扱うと認める委託先を選定します。</p>
                    </div>
                </section>

                <section>
                    <h2>6. 個人情報の開示、訂正等の請求への対応</h2>
                    <div>
                        <p>弊社は、お客様から、ご自身の個人情報に関する利用目的の通知、開示、訂正等（訂正、追加、削除）、利用停止等（利用停止、消去）または第三者提供の停止のご請求があった場合には、お客様がご本人であることを確認させていただいたうえで、応じられない合理的理由の無い限り、当社は適切かつ迅速にこれに対応します。</p>
                    </div>
                </section>

                <section>
                    <h2>7. Cookie（クッキー）およびSSLの利用について</h2>
                    <div>
                        <p>Cookie（クッキー）は、お客様が当社のサイトに再度訪問された際、より便利に当サイトを閲覧して頂くためのものであり、お客様のプライバシーを侵害するものではなく、お客様のコンピューターへ悪影響を及ぼすことはありません。又、当社のサイトでは個人情報を入力して頂く部分にはすべて SSL（Secure Sockets Layer）のデータ暗号化システムを利用しております。ただし、インターネット通信の性格上、セキュリティを完全に保証するものではありません。予めご了承ください。</p>
                    </div>
                </section>

                <section>
                    <h2>8. Cookie（クッキー）の設定について</h2>
                    <div>
                        <p>ユーザーは、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」等 から選択できます。
                        設定方法は、ブラウザにより異なります。クッキーに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。<br />すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。</p>
                    </div>
                </section>

                <section>
                    <h2>9. 改定</h2>
                    <div>
                        <p>当社は、本「個人情報の取扱いについて」の全部または一部を改定することがあります。変更後の本方針については、当社が別途定める場合を除いて、当サイトに掲載した時から効力を生じるものとします。重要な変更がある場合には、サイト上でお知らせします。</p>
                    </div>
                </section>

                <section>
                    <h2>10. 個人情報に関するお問い合わせ</h2>
                    <div>
                        <p>お客様の個人情報の取扱いに関するお問い合わせ等は、店舗または、下記までご連絡ください。</p>
                        <dl>
                            <dt>快適ライフサービス株式会社</dt>
                            <dd>〒152-0031<br />東京都目黒区中根2-12-10<br />Mail：info@thefit.jp</dd>
                        </dl>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PrivacyPage
